
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import NewTagModal from "@/components/modals/forms/NewTagModal.vue"
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'

export default defineComponent({
  components: {
    NewTagModal,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const refNewTagModal = ref({} as any)

    const onNewTag = (item) => {
      if (refNewTagModal.value != null) {
        refNewTagModal.value.set_item(item)
      }
    }

    const tag = ref({})

    const new_tag = (item) => {
      store
        .dispatch(Actions.CREATE_TAG, {name: item.name})
        .then(() => {
          query()
          tag.value = {}
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const tags = ref([] as any)

    const query = () => {
      store
        .dispatch(Actions.GET_TAGS)
        .then(() => {
          tags.value = store.getters.currentTags
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    query()

    const onDelete = (id) => {
      Swal.fire({
        title: '确认删除？',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: '确认',
        // denyButtonText: '取消',
        cancelButtonText: '取消'
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DELETE_TAG, id)
            .then(() => {
              query()
            })
            .catch(() => {
              // const [error] = Object.keys(store.getters.getErrors);
              // Swal.fire({
              //   text: store.getters.getErrors[error],
              //   icon: "error",
              //   buttonsStyling: false,
              //   confirmButtonText: "Try again!",
              //   customClass: {
              //     confirmButton: "btn fw-bold btn-light-danger",
              //   },
              // });
            })
          // Swal.fire('Saved!', '', 'success')
        // } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
      })
    }

    const onNewTagItem = (item) => {
      item.is_new = true
    }

    const createNewTagItem = (item, e) => {
      var name = e.target.value

      if (name == '') {
        item.is_new = false
        return
      }

      store
        .dispatch(Actions.CREATE_TAG_ITEM, {id: item.id, values: {name: name}})
        .then(() => {
          query()
          item.is_new = false
          e.target.value = ''
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    const deleteNewTagItem = (item, _item) => {
      store
        .dispatch(Actions.DELETE_TAG_ITEM, {id: item.id, values: {id: _item.id}})
        .then(() => {
          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    return {
      tags,
      refNewTagModal,
      new_tag,
      onNewTag,
      onDelete,
      onNewTagItem,
      createNewTagItem,
      deleteNewTagItem
    }
  }
})
